import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import { makeStyles, styled } from "@mui/styles";
import React, { useState, useContext, useEffect } from "react";
import Title from "../components/title";
import { useHistory } from "react-router-dom";
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Typography,
  useTheme,
} from "@mui/material";
import LoadingBackdrop from "../components/loading-backdrop";
import { ErrorContext, UserContext } from "../components/app-routes";
import { billingApi } from "../services/api/billing";
import { settingsApi } from "../services/api/settings";
import mixpanel from "mixpanel-browser";
import { FastField, FieldProps, Form, Formik } from "formik";
import * as yup from "yup";
import { AppTextField } from "../components/text-field";
import { emailChangeApi } from "../services/api/email-change";

const useStyles = makeStyles((theme) => ({
  root: {
    "& label.Mui-focused": {
      color: "black",
    },
    "& label": {
      color: "black",
    },
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
}));

export default function SettingsPage() {
  const classes = useStyles();
  const history = useHistory();
  const [busy, setBusy] = useState(false);
  const [openEmailChangeDialog, setOpenEmailChangeDialog] = useState(false);
  const { handleError } = useContext(ErrorContext);
  const theme = useTheme();
  const context = useContext(UserContext);
  const grower = context.grower;
  const user = context.user;

  if (!grower) {
    throw new Error("Invalid state");
  }

  useEffect(() => {
    mixpanel.track("View Settings Page");
  }, []);

  const handleClickBilling = async () => {
    setBusy(true);
    try {
      const session = await billingApi.createPortalSession();
      window.location.assign(session.Url);
    } catch (error) {
      handleError(error);
    } finally {
      setBusy(false);
    }
  };

  const handleChangeWorkflow = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setBusy(true);

    try {
      const value = (event.target as HTMLInputElement).value;
      await settingsApi.update({
        workflow: value,
      });

      context.state.context!.grower!.Workflow = value as any;
      context.setState(context.state);

      mixpanel.track("Update Workflow", {
        value,
      });
      mixpanel.people.set({
        workflow: value,
      });
    } catch (error) {
      handleError(error);
    } finally {
      setBusy(false);
    }
  };

  const onSubmit = async (values: { email: string }) => {
    setBusy(true);

    try {
      await emailChangeApi.change(values.email);
      setOpenEmailChangeDialog(true);
      mixpanel.track("Request Email Change");
    } catch (error) {
      handleError(error);
    } finally {
      setBusy(false);
    }
  };

  return (
    <Container maxWidth="lg" className={classes.container}>
      <Grid item xs={12}>
        <Paper className={classes.paper}>
          <Title>Settings</Title>
          <Typography variant="h6">Profile</Typography>
          <Divider />
          <Box sx={{ marginTop: theme.spacing(2) }}>
            <Formik
              initialValues={{
                email: user.Email,
              }}
              validationSchema={yup.object({
                email: yup
                  .string()
                  .email("Enter a valid email")
                  .required("Email is required"),
              })}
              onSubmit={onSubmit}
            >
              {(f) => (
                <>
                  <Form noValidate>
                    <FastField name="email">
                      {(fieldProps: FieldProps) => (
                        <AppTextField
                          {...fieldProps}
                          label="Email"
                          type="email"
                        />
                      )}
                    </FastField>
                    <Box sx={{ marginTop: theme.spacing(1) }}>
                      <Button
                        variant="contained"
                        color="primary"
                        type="submit"
                        disabled={busy || !f.dirty || !!f.errors.email}
                      >
                        Save
                      </Button>
                    </Box>
                  </Form>
                </>
              )}
            </Formik>
          </Box>
          <Typography sx={{ marginTop: theme.spacing(4) }} variant="h6">
            Billing
          </Typography>
          <Divider />
          <Box>
            <Button
              sx={{ marginTop: theme.spacing(2) }}
              variant="contained"
              color="primary"
              onClick={handleClickBilling}
            >
              Go To Billing Portal
            </Button>
          </Box>
          <Typography sx={{ marginTop: theme.spacing(4) }} variant="h6">
            Workflow
          </Typography>
          <Divider />
          <Box sx={{ marginTop: theme.spacing(2) }}>
            <FormControl className={classes.root}>
              <FormLabel>
                SeedLeaf offers two workflows: Simple or Advanced.
                <br />
                <br />
                <b>Simple:</b>
                <br /> Recommended for growers who do not need to keep exact
                records and just need Seedleaf as a crop planner.
                <br />
                <br />
                <b>Advanced:</b>
                <br />
                Recommended for growers wanting to keep careful records that are
                audit-ready. The advanced workflow is a bit more work, but
                ensures accurate crop production and packaging records.
                <br />
                <br />
                PLEASE NOTE: In the Advanced Workflow, Tasks must be completed
                for their data to appear in reports.
                <br />
                <br /> Not sure what you want to do? Learn more{" "}
                <a
                  target="_blank"
                  href="https://seedleaf.freshdesk.com/support/solutions/articles/72000625772-choosing-a-seedleaf-workflow-simple-or-advanced-"
                >
                  here
                </a>
                . You can change your setting at any time.
                <br />
                <br />
              </FormLabel>
              <RadioGroup
                name="controlled-radio-buttons-group"
                value={grower.Workflow}
                onChange={handleChangeWorkflow}
              >
                <FormControlLabel
                  value="simple"
                  control={<Radio />}
                  label="Simple"
                />
                <FormControlLabel
                  value="advanced"
                  control={<Radio />}
                  label="Advanced"
                />
              </RadioGroup>
            </FormControl>
          </Box>
        </Paper>
      </Grid>
      <LoadingBackdrop open={busy} />
      <Dialog
        open={openEmailChangeDialog}
        onClose={() => setOpenEmailChangeDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Email Change</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            A confirmation email has been sent to the new email address. To
            complete the change, click on the link in that email.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenEmailChangeDialog(false)} autoFocus>
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
}
